import React from 'react';

const Contact = () => {
  return (
    <div>
      <div className="section hero v3 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="w-layout-grid grid-2-columns contact-v1">
              <div
                data-w-id="e1483f24-db94-8b21-f308-3658cccb182f"
                // style={{
                //   transform:
                //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1,
                //   transformStyle: 'preserve-3d'
                // }}
                className="inner-container _489px _100---tablet"
              >
                <div className="text-center---tablet">
                  <h1 className="display-1">
                    <span className="heading-span-secondary-2">Let's talk</span>
                  </h1>
                  <p className="mg-bottom-32px">
                    this doesn't have to end here (-:
                  </p>
                </div>
                <div className="inner-container _360px _100---tablet contact-info">
                  <div className="card contact-card">
                    {/* <a
                      data-w-id="8562c152-2498-e448-de3c-2722b9eef1d0"
                      href="mailto:hello@christienng.com"
                      className="text-decoration-none w-inline-block"
                    > */}
                    <div className="flex-horizontal start link---icon-left">
                      <img
                        src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633d9a460fc6857e260d0f2b_envelope-icon-large-paperfolio-webflow-template.svg"
                        loading="eager"
                        alt="envelope icon"
                      />
                      <div
                        className="contact-link contact"
                        style={{ color: 'rgb(11, 11, 11)' }}
                      >
                        hello@christienng.com
                      </div>
                    </div>
                    {/* </a> */}
                    {/* <a
                      data-w-id="5ccae694-b00c-0a0c-9b26-67ad67709985"
                      href="tel:(909)552-2969"
                      className="text-decoration-none w-inline-block"
                    >
                      <div className="flex-horizontal start link---icon-left">
                        <img
                          src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633d9a5fec957e53ae8857ce_phone-icon-large-paperfolio-webflow-template.svg"
                          loading="eager"
                          alt="phone icon"
                        />
                        <div
                          className=" contact"
                          style={{ color: 'rgb(11, 11, 11)' }}
                        >
                          (909) 552 - 2969
                        </div>
                      </div>
                    </a> */}
                  </div>
                </div>
              </div>
              <div
                id="w-node-e1483f24-db94-8b21-f308-3658cccb1849-f4b2908c"
                data-w-id="e1483f24-db94-8b21-f308-3658cccb1849"
                // style={{
                // transform:
                //   'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1,
                //   transformStyle: 'preserve-3d'
                // }}
                className="inner-container _665px width-100 _100---tablet"
              >
                <div className="card form w-form">
                  <form
                    id="wf-form-Contact-Form"
                    name="wf-form-Contact-Form"
                    data-name="Contact Form"
                    action="https://getform.io/f/b3b4d75f-f983-4c0e-bc18-26fbf2bf08e1"
                    method="POST"
                    aria-label="Contact Form"
                  >
                    <div className="w-layout-grid grid-2-columns form">
                      <div className="position-relative">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="input icon-input name-icon w-input"
                          maxLength="256"
                          name="Name"
                          data-name="Name"
                          placeholder="Your Name Here"
                          id="name"
                          required=""
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="input icon-input email-icon w-input"
                          maxLength="256"
                          name="Email"
                          data-name="Email"
                          placeholder="contact@email.com"
                          id="email"
                          required=""
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="tel"
                          className="input icon-input phone-icon w-input"
                          maxLength="256"
                          name="Phone"
                          data-name="Phone"
                          placeholder="(111) 111 - 1111"
                          id="phone"
                          required=""
                        />
                      </div>
                      <div>
                        <label htmlFor="subject">Subject</label>
                        <input
                          type="text"
                          className="input w-input"
                          maxLength="256"
                          name="Subject"
                          data-name="Subject"
                          placeholder="Hi"
                          id="subject"
                          required=""
                        />
                      </div>
                      <div id="w-node-e1483f24-db94-8b21-f308-3658cccb185d-f4b2908c">
                        <label htmlFor="message">Message</label>
                        <textarea
                          id="message"
                          name="Message"
                          maxLength="5000"
                          data-name="Message"
                          placeholder="Please write your message..."
                          className="text-area w-input"
                        ></textarea>
                      </div>
                      <input
                        type="submit"
                        data-wait="Please wait..."
                        value="Send Message"
                        id="w-node-e1483f24-db94-8b21-f308-3658cccb1861-f4b2908c"
                        className="btn-primary w-button"
                      />
                    </div>
                  </form>
                  <div
                    className="success-message w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Contact Form success"
                  >
                    <div>
                      <div className="line-rounded-icon success-message-check large">
                        
                      </div>
                      <div className="heading-h3-size mg-bottom-8px">
                        Thank you
                      </div>
                      <div>
                        Your message has been submitted. <br />I will get back
                        to you within 24-48 hours.
                      </div>
                    </div>
                  </div>
                  <div
                    className="error-message w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Contact Form failure"
                  >
                    <div>Oops! Something went wrong.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section pd-bottom-220px pd-top-0 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div
              data-w-id="fa0ccb41-7de7-02fb-2dd3-449c93bea505"
              style={{
                transform:
                  'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                opacity: 1,
                transformStyle: 'preserve-3d'
              }}
              className="inner-container _725px center"
            >
              <div className="text-center mg-bottom-32px">
                <h2 className="display-2">
                  <span className="z-index-1">Frequently </span>
                  <span className="heading-span-secondary-4">
                    Asked Questions
                  </span>
                </h2>
                <p className="mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                  aliquam, purus sit amet luctus venenatis, lectus magna
                  porttitor <span className="text-no-wrap">rhoncus dolor.</span>
                </p>
              </div>
            </div>
          </div>
          <div
            data-w-id="fa0ccb41-7de7-02fb-2dd3-449c93bea50b"
            style={{
              transform:
                'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              opacity: 1,
              transformStyle: 'preserve-3d'
            }}
            className="inner-container _894px center"
          >
            <div className="w-layout-grid grid-1-column gap-32px">
              <div
                data-w-id="fa0ccb41-7de7-02fb-2dd3-449c93bea50d"
                className="accordion-item-wrapper"
              >
                <div className="accordion-content-wrapper">
                  <div className="accordion-header">
                    <h3 className="accordion-title">
                      Are you open for freelance / contract work?
                    </h3>
                    <div className="accordion-side right-side">
                      <div className="btn-circle-secondary small accordion-btn">
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line vertical"
                        ></div>
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line horizontal"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '0px',
                      opacity: 0,
                      transform:
                        'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d'
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit id
                      venenatis pretium risus euismod dictum egestas orci netus
                      feugiat ut egestas ut sagittis dolor sit tincidunt
                      phasellus elit etiam cursus orci in. Id
                      <span className="text-no-wrap">sed montes.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                data-w-id="2e1d3c07-47c8-d964-d873-fae2d2e9a181"
                className="accordion-item-wrapper"
              >
                <div className="accordion-content-wrapper">
                  <div className="accordion-header">
                    <h3 className="accordion-title">
                      Are you open for design collaborations?
                    </h3>
                    <div className="accordion-side right-side">
                      <div className="btn-circle-secondary small accordion-btn">
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line vertical"
                        ></div>
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line horizontal"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '0px',
                      opacity: 0,
                      transform:
                        'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d'
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit id
                      venenatis pretium risus euismod dictum egestas orci netus
                      feugiat ut egestas ut sagittis dolor sit tincidunt
                      phasellus elit etiam cursus orci in. Id
                      <span className="text-no-wrap">sed montes.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                data-w-id="8e423f9d-2173-e7db-5dec-0fa886a01914"
                className="accordion-item-wrapper"
              >
                <div className="accordion-content-wrapper">
                  <div className="accordion-header">
                    <h3 className="accordion-title">
                      Are you open for guest-posts on your blog?
                    </h3>
                    <div className="accordion-side right-side">
                      <div className="btn-circle-secondary small accordion-btn">
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line vertical"
                        ></div>
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line horizontal"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '0px',
                      opacity: 0,
                      transform:
                        'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d'
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit id
                      venenatis pretium risus euismod dictum egestas orci netus
                      feugiat ut egestas ut sagittis dolor sit tincidunt
                      phasellus elit etiam cursus orci in. Id
                      <span className="text-no-wrap">sed montes.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                data-w-id="d7dacebe-1043-f4eb-277d-19b308ef00c9"
                className="accordion-item-wrapper"
              >
                <div className="accordion-content-wrapper">
                  <div className="accordion-header">
                    <h3
                      className="accordion-title"
                      style={{
                        transform:
                          'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d'
                      }}
                    >
                      Are you currently looking for fulltime roles?
                    </h3>
                    <div className="accordion-side right-side">
                      <div
                        className="btn-circle-secondary small accordion-btn"
                        style={{
                          backgroundColor: 'rgb(255, 255, 255)',
                          borderColor: 'rgb(11, 11, 11)'
                        }}
                      >
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                            backgroundColor: 'rgb(11, 11, 11)'
                          }}
                          className="accordion-btn-line vertical"
                        ></div>
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                            backgroundColor: 'rgb(11, 11, 11)'
                          }}
                          className="accordion-btn-line horizontal"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      opacity: 0,
                      transform:
                        'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d',
                      height: '0px'
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit id
                      venenatis pretium risus euismod dictum egestas orci netus
                      feugiat ut egestas ut sagittis dolor sit tincidunt
                      phasellus elit etiam cursus orci in. Id
                      <span className="text-no-wrap">sed montes.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                data-w-id="8e8e69fc-95ca-cf19-7406-ea0e9b263383"
                className="accordion-item-wrapper"
              >
                <div className="accordion-content-wrapper">
                  <div className="accordion-header">
                    <h3 className="accordion-title">
                      What's your past experience on design?
                    </h3>
                    <div className="accordion-side right-side">
                      <div className="btn-circle-secondary small accordion-btn">
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line vertical"
                        ></div>
                        <div
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                          }}
                          className="accordion-btn-line horizontal"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '0px',
                      opacity: 0,
                      transform:
                        'translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d'
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit id
                      venenatis pretium risus euismod dictum egestas orci netus
                      feugiat ut egestas ut sagittis dolor sit tincidunt
                      phasellus elit etiam cursus orci in. Id
                      <span className="text-no-wrap">sed montes.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Contact;
