import React, { useState } from 'react';

const ResumeCardM = ({ experience }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={'card resume-card-v2 ' + (experience.current ? 'last' : '')}
      style={{ opacity: 1 }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className={
          'resume-card-left-content ' + (experience.current ? 'last' : '')
        }
      >
        <div className="resume-card-period-v2">{experience.dates}</div>
      </div>
      <div className="resume-card-content-right">
        <div className="inner-container _480px">
          <h3 className="display-4">{experience.title}</h3>
          <h4>@{experience.companyName}</h4>
          <p>{experience.description}</p>
          {isOpen ? (
            <ul>
              {experience.duties.map(d => {
                return <li>{d}</li>;
              })}
            </ul>
          ) : (
            ''
          )}
        </div>
        <div className="image-wrapper right-shadow-circle resume-card-icon">
          <a href={experience.link}>
            <img
              src={experience.companyLogo}
              style={
                experience.companyName === 'HackforLA'
                  ? { transform: 'scale(1.2)' }
                  : {}
              }
              loading="eager"
              alt="facebook logo"
              className="image"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResumeCardM;
