import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PasswordProtect from './routes/PasswordProtect';
import Home from './routes/Home';
import About from './routes/About';
import Portfolio from './routes/Portfolio';
// import Blog from './pages/Blog';
import Contact from './routes/Contact';
import NotFound404 from './routes/NotFound404';

const URLRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/portfolio" element={<Portfolio/>} />
        {/* <Route exact path="/blog/:post" element={<Blog />} /> */}
        <Route exact path="/password" element={<PasswordProtect/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route path="*" element={<NotFound404/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default URLRoutes;