import React from 'react';
import { PROJECTS } from '../data/projects';
import ProjectCard from '../components/cards/ProjectCard';

const Portfolio = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

  if (!isAuthenticated) {
    window.location.href = '/password';
    return null;
  }

  return (
    <div>
      <div className="section hero v3 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _615px center">
            <div className="inner-container _550px---mbl center">
              <div
                // style={{
                //   transform:
                //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1
                // }}
                className="text-center mg-bottom-64px"
              >
                <h1 className="display-1">Portfolio</h1>
                <p className="mg-bottom-0">
                  Some recent projects I've worked on
                </p>
              </div>
            </div>
          </div>
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="w-dyn-list">
                <div role="list" className="grid-1-column gap-32px w-dyn-items">
                  {PROJECTS.map(p => {
                    return <ProjectCard key={p.title} props={p} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          // style={{
          //   transform:
          //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
          //   opacity: 1
          // }}
          className="buttons-row center"
        >
          <a href="https://github.com/cng008" className="btn-primary w-button">
            View more projects on my GitHub
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
