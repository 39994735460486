import wanderLogo from '../images/wander-logo.png';
import seismicsafeLogo from '../images/seismicsafe_logo.png';
import spotifyLogo from '../images/spotify_logo.png';
import pokemonLogo from '../images/pokemon_logo.png';
import twitterLogo from '../images/twitter_logo.png';
import wanderPreview from '../images/wander.png';
import seismicsafePreview from '../images/seismicsafe.png';
import spotifyPreview from '../images/spotify.png';
import pokemonPreview from '../images/pokemon.png';
import twitterPreview from '../images/twitter.png';

export const PROJECTS = [
  {
    companyLogo: wanderLogo,
    date: 'Mar 2023 - Present',
    title: 'Wander Travel',
    description:
      'A travel planning app that seamlessly connects and empowers users to collaboratively plan trips with friends for a delightful travel experience for everyone involved.',
    technologies:
      'React.js, TypeScript, JavaScript, Redux, Firestore, Material UI, Yarn, Figma, Clickup',
    liveSite: 'https://wander.live/',
    image: wanderPreview,
    bgColor: '#75D7DE'
  },
  {
    companyLogo: seismicsafeLogo,
    date: 'Apr 2023',
    title: 'SeismicSafe',
    description:
      'Notifies users of earthquake occurrences near their specified location with the ultimate goal of improving and promoting community safety.',
    technologies: 'Node.js, Express, Firebase, Figma, Twilio, Velo by Wix',
    liveSite: 'https://devpost.com/software/seismic-safe',
    // liveSite: 'https://seismicsafe.tech/',
    // githubRepo: 'https://github.com/thedvo/seismic-safe',
    image: seismicsafePreview,
    bgColor: '#F9C74F'
  },
  {
    companyLogo: spotifyLogo,
    // companyLogo:
    //   'https://storage.googleapis.com/pr-newsroom-wp/1/2018/11/Spotify_Logo_CMYK_Green.png',
    date: 'Sept 2022',
    title: 'Spotify web player clone',
    description:
      'Browse and look up by albums, songs, and artists and save them to playlists.',
    technologies:
      'React.js, JavaScript, Node.js, Express, PostgreSQL, Material UI, Unit Tests, Surge, Heroku',
    liveSite: 'http://cng008-spotify-clone.surge.sh/',
    githubRepo: 'https://github.com/cng008/spotify-web-player-clone',
    image: spotifyPreview,
    bgColor: '#1DB954'
  },
  {
    companyLogo: pokemonLogo,
    // companyLogo:
    //   'https://www.freepnglogos.com/uploads/pokemon-logo-text-png-7.png',
    date: 'Mar 2022',
    title: 'Pokédex',
    description:
      'Search and view a comprehensive archive of Pokémon and save your favorites to your account.',
    technologies:
      'Python, Flask, Jinja, HTML/CSS, Bootstrap, SQLAlchemy, WTForms, Bcrypt, Unit Tests, Heroku',
    liveSite: 'https://pokedex-cng.herokuapp.com/',
    githubRepo: 'https://github.com/cng008/pokedex',
    image: pokemonPreview,
    bgColor: '#db7069'
  },
  {
    companyLogo: twitterLogo,
    // companyLogo:
    //   'https://assets.stickpng.com/images/580b57fcd9996e24bc43c53e.png',
    date: 'Mar 2022',
    title: 'Twitter Clone',
    description: 'Sign up, tweet tweets, follow others.',
    technologies:
      'Python, HTML, CSS, Bootstrap, Flask, SQLAlchemy, WTForms, Bcrypt, Jinja, Unit Tests',
    liveSite: 'https://cng-warbler.herokuapp.com/',
    githubRepo: 'https://github.com/cng008/26_warbler',
    image: twitterPreview,
    bgColor: '#29aff5'
  }
];
