import React from 'react';
import logo from '../images/christienng_logo-dark.png';

const Nav = () => {
  return (
    <div className="page-wrapper">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="header-wrapper w-nav"
        style={{ opacity: 1 }}
      >
        <div className="container-default nav-container w-container">
          <div className="header-content-wrapper shadow-neutral-800---distance-7px">
            <a
              href="/"
              aria-current="page"
              className="header-logo-link w-nav-brand w--current"
              aria-label="home"
            >
              <img
                src={logo}
                loading="eager"
                alt="paperfolio logo"
                className="header-logo"
                style={{ width: '3.5em' }}
              />
            </a>

            {/* NAV MENU */}
            <div className="header-middle">
              <nav
                role="navigation"
                className="header-nav-menu-wrapper w-nav-menu"
                // style={{ transform: 'translateY(0px) translateX(0px)' }}
              >
                <ul className="header-nav-menu-list">
                  <li className="header-nav-list-item middle">
                    <a
                      href="/"
                      className="header-nav-link w-nav-link"
                      style={{ maxWidth: '778px' }}
                    >
                      Home
                    </a>
                  </li>
                  <li className="header-nav-list-item middle">
                    <a
                      href="/about"
                      className="header-nav-link w-nav-link"
                      style={{ maxWidth: '778px' }}
                    >
                      About
                    </a>
                  </li>
                  <li className="header-nav-list-item middle">
                    <a
                      href="/about#my-resume"
                      className="header-nav-link w-nav-link"
                      style={{ maxWidth: '778px' }}
                    >
                      Résumé
                    </a>
                  </li>
                  <li className="header-nav-list-item middle">
                    <a
                      href="/portfolio"
                      className="header-nav-link w-nav-link"
                      style={{ maxWidth: '778px' }}
                    >
                      Portfolio
                    </a>
                  </li>
                  {/* <li className="header-nav-list-item middle last">
                    <div
                      data-hover="true"
                      data-delay="0"
                      className="dropdown-wrapper w-dropdown"
                      style={{ maxWidth: '778px' }}
                    >
                      <div
                        className="dropdown-toggle header-nav-link w-dropdown-toggle"
                        id="w-dropdown-toggle-0"
                        aria-controls="w-dropdown-list-0"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabIndex="0"
                      >
                        <div>More</div>
                        <div className="line-rounded-icon dropdown-arrow">
                          
                        </div>
                      </div>
                      <nav
                        className="dropdown-column-wrapper w-dropdown-list"
                        id="w-dropdown-list-0"
                        aria-labelledby="w-dropdown-toggle-0"
                        style={{ display: 'none', opacity: 0 }}
                      >
                        <div className="card dropdown">
                          <div className="w-layout-grid grid-1-column dropdown-link-column">
                            <div className="grid-2-columns dropdown-grid">
                              <div className="div-block-7">
                                <div className="text-400 bold dropdown-page">
                                  Pages
                                </div>
                                <div className="footer-lists-wrapper">
                                  <ul className="footer-list-wrapper">
                                    <li className="footer-list-item">
                                      <a
                                        href="/creative"
                                        className="footer-link dropdown"
                                        tabIndex="0"
                                      >
                                        Creative
                                      </a>
                                      <ul>
                                        <li>
                                          <a
                                            href="/creative/marketing"
                                            className="footer-link dropdown"
                                            tabIndex="0"
                                          >
                                            Marketing
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="/creative/freelance"
                                            className="footer-link dropdown"
                                            tabIndex="0"
                                          >
                                            Freelance
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="/creative/photography"
                                            className="footer-link dropdown"
                                            tabIndex="0"
                                          >
                                            Photography
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li className="footer-list-item">
                                      <a
                                        href="/blog"
                                        className="footer-link dropdown"
                                        tabIndex="0"
                                      >
                                        Blog
                                      </a>
                                    </li>
                                    <li className="footer-list-item">
                                      <a
                                        href="/contact"
                                        className="footer-link dropdown"
                                        tabIndex="0"
                                      >
                                        Contact
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </li> */}
                </ul>
              </nav>
            </div>

            {/* CONTACT BUTTON */}
            <div className="header-right-side">
              <a
                href="/contact"
                className="btn-primary small header-btn-hidde-on-mb w-button"
              >
                <span className="line-rounded-icon"></span>
              </a>
              <div
                className="hamburger-menu-wrapper w-nav-button"
                aria-label="menu"
                role="button"
                tabIndex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div
                  className="hamburger-menu-bar top"
                  // style={{
                  // transform:
                  //   'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   transformStyle: 'preserve-3d'
                  // }}
                ></div>
                <div
                  className="hamburger-menu-bar bottom"
                  // style={{
                  // transform:
                  //   'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   transformStyle: 'preserve-3d'
                  // }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
