import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordProtect = () => {
  const navigate = useNavigate();
  const [isWrongPassword, setIsWrongPassword] = useState(false);

  const checkPassword = (password) => {
      const correctPassword = 'peanut1';
      if (password.toLowerCase() === correctPassword) {
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/');
        window.location.reload();
      } else {
        setIsWrongPassword(true);
      }
  };

  return (
    <div className="utility-page-wrap password-page">
      <div className="utility-page-content w-password-page w-form">
        <form
          action="/.wf_auth"
          method="post"
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="utility-page-form w-password-page"
          aria-label="Email Form"
          onSubmit={(e) => {
            e.preventDefault();
            const passwordInput = (e.target).elements.namedItem('password');
            checkPassword(passwordInput?.value);
          }}
        >
          <div className="container-default w-container">
            <div className="inner-container _600px---mbl center">
              <div
                // style={{
                //   transform:
                //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1,
                //   transformStyle: 'preserve-3d'
                // }}
                className="card no-hover"
              >
                <div className="pd---content-inside-card large">
                  <div className="mg-bottom-54px">
                    <div className="image-wrapper right-shadow-circle password-page-icon">
                      <img
                        src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/634488bc96a0e0dad9c054cb_password-protected-paperfolio-webflow-template.svg"
                        loading="eager"
                        alt="password protected"
                      />
                    </div>
                  </div>
                  <h1 className="display-2 mg-bottom-12px">
                    Password protected
                  </h1>
                  <p className="mg-bottom-24px keep">
                    Enter the password that was given to you.
                  </p>
                  <div className="w-layout-grid grid-1-column full-width gap-row-24px">
                    <input
                      type="password"
                      className="input w-password-page w-input"
                      autoFocus="true"
                      maxLength="256"
                      name="password"
                      data-name="field"
                      placeholder="Enter your password"
                      id="password"
                    />
                    {isWrongPassword && (
                      <div className="error-message password w-password-page w-form-fail">
                        <div>Invitation code not valid</div>
                      </div>
                    )}
                    <input
                      type="submit"
                      value="Enter now"
                      data-wait="Please wait..."
                      className="btn-primary w-password-page w-button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: 'none' }}
            className="w-password-page w-embed w-script"
          >
            <input type="hidden" name="path" value="<%WF_FORM_VALUE_PATH%>" />
            <input type="hidden" name="page" value="<%WF_FORM_VALUE_PAGE%>" />
          </div>
        </form>
      </div>
    </div>
  );
};

PasswordProtect.displayName = 'PasswordProtect';
export default PasswordProtect;
