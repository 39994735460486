import React from 'react';
import logo from '../images/christienng_logo-light.png';

const Footer = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

  return (
    <footer className="footer-wrapper">
      <div className="container-default w-container">
        <div className="footer-top border-bottom">
          <div className="w-layout-grid grid-footer-4-columns-v14">
            <div
              className="inner-container _500px full-width-tbl"
              // style={{
              // transform:
              //   'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              // transformStyle: 'preserve-3d',
              //   opacity: 1
              // }}
            >
              <div className="inner-container _400px---tablet">
                <div className="inner-container _350px---mbl">
                  <a href="/" className="footer-logo-wrapper w-inline-block">
                    <img
                      src={logo}
                      loading="eager"
                      alt="paperfolio logo white"
                      className="footer-logo"
                      style={{ width: '3.5em' }}
                    />
                  </a>
                  <p
                    className="text-medium color-neutral-300 mg-bottom-32px"
                    style={{ fontSize: '14px' }}
                  >
                    thank you for taking the time to look through my portfolio.
                    i hope through this website you've gotten to know a little
                    more about me. please come back in the future, as i will ll
                    be updating my site with the work that is made. <br />​
                    <br />
                    please{' '}
                    <a href="/contact">
                      contact me
                    </a>
                    {' '}if you'd like to learn more or if you'd just like to say hello!
                  </p>
                </div>
              </div>
              {isAuthenticated && (
                <div className="w-layout-grid social-media-grid-top">
                  <a
                    href="https://linkedin.com/in/christienng"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#0A66C2' }}
                    >
                      
                    </div>
                  </a>
                  <a
                    href="https://github.com/cng008"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div className="social-icon-font"></div>
                  </a>
                  <a
                    href="https://instagram.com/ngchristien"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#E4405F' }}
                    >
                      
                    </div>
                  </a>
                  {/* <a
                    href="https://www.youtube.com/channel/UCiL2-QVLStTTgDuZX_cfnBA"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#CD201F' }}
                    >
                      
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/christiien"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#1877F2' }}
                    >
                      
                    </div>
                  </a>
                  <a
                    href="https://soundcloud.com/christiien"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#FF3300' }}
                    >
                      
                    </div>
                  </a> */}
                  <a
                    href="https://open.spotify.com/user/christienrenee?si=ZgYNlNTVRQ6oEY1zaSRnYQ"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#1DB954' }}
                    >
                      
                    </div>
                  </a>
                  <a
                    href="live:christienng"
                    target=""
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#00AFF0' }}
                    >
                      
                    </div>
                  </a>
                  <a
                    href="id:christienng"
                    target=""
                    rel="noreferrer"
                    className="social-icon-square w-inline-block"
                  >
                    <div
                      className="social-icon-font"
                      style={{ color: '#09B83E' }}
                    >
                      
                    </div>
                  </a>
                </div>
              )}
            </div>
            <div
              className="full-width-mobile"
              // style={{
              //   transform:
              //     'translate3d(0px, 10%, 0px ) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d',
              //   opacity: 1
              // }}
            >
              <div className="text-400 medium footer-title">Pages</div>
              <div className="footer-lists-wrapper">
                <ul className="footer-list-wrapper">
                  <li className="footer-list-item">
                    <a href="/" className="footer-link">
                      Home
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/about" className="footer-link">
                      About
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/portfolio" className="footer-link">
                      Portfolio
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a
                      href="https://docs.google.com/document/d/1bEq4WCMKdNr1rxbbaBanKKKopOEt0AoF/edit"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-link"
                    >
                      Résumé
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/contact" className="footer-link">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="footer-contact"
              // style={{
              //   transform:
              //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d',
              //   opacity: 1
              // }}
            >
              <div className="text-400 medium footer-title">Contact me</div>
              <ul className="footer-list-wrapper">
                <li className="footer-list-item">
                  <a
                    href="/contact"
                    className="link-wrapper color-neutral-300 w-inline-block"
                  >
                    <div
                      className="social-icon-square icon-left"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    >
                      <img
                        src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633710c40bfc413cb9d3aba0_email-icon-paperfolio-webflow-template.svg"
                        loading="eager"
                        alt="email icon"
                      />
                    </div>
                    <div
                      className=" contact"
                      style={{ color: 'rgb(255, 255, 255)' }}
                    >
                      hello@christienng.com
                    </div>
                  </a>
                </li>
                {/* <li className="footer-list-item last">
                  <a
                    href="tel:(909)552-2969"
                    className="link-wrapper color-neutral-300 w-inline-block"
                  >
                    <div
                      className="social-icon-square icon-left"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    >
                      <img
                        src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633710c0759d50c0ebb5cb14_phone-icon-paperfolio-webflow-template.svg"
                        loading="eager"
                        width="24"
                        alt="phone icon"
                      />
                    </div>
                    <div
                      className="link-text contact"
                      style={{ color: 'rgb(255, 255, 255)' }}
                    >
                      (909) 552 - 2969
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom border-top-0px">
          <div className="inner-container _500px---mbl center">
            <p
              className="color-neutral-300 text-medium mg-bottom-0"
              // style={{
              //   transform:
              //     'translate3d(-25px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              //   opacity: 1
              // }}
            >
              Copyright © Paperfolio X | Designed by{' '}
              <a
                href="https://brixtemplates.com/#webflow-templates"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                BRIX Templates
              </a>{' '}
              -{' '}
              <a
                href="https://webflow.com"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Webflow
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
