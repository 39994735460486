import React from 'react';

const NotFound404 = () => {
  return (
    <div className="utility-page-wrap not-found">
      <div className="container-default w-container">
        <div className="position-relative z-index-1">
          <div className="flex-horizontal">
            <div
              // style={{
              //   transform:
              //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              //   opacity: 1,
              //   transformStyle: 'preserve-3d'
              // }}
              className="position-absolute _404-not-found"
            >
              <div className="_404-not-found-number">404</div>
            </div>
            <div className="grid-2-columns _1-col-tablet position-relative">
              <div className="flex-horizontal position-relative">
                <div className="image-wrapper width-60">
                  <img
                    src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633dea45e1cbb3429b10d94d_page-not-found-icon-paperfolio-webflow-template.svg"
                    loading="eager"
                    style={{ opacity: 1 }}
                    alt="Page Not Found - Paperfolio Webflow Template"
                  />
                </div>
              </div>
              <div className="inner-container _600px---tablet center">
                <div
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1,
                  //   transformStyle: 'preserve-3d'
                  // }}
                  className="utility-page-content mg-bottom-0 position-relative w-form"
                >
                  <div className="display-1 mg-bottom-8px">Oops!</div>
                  <h1 className="display-2">Page Not Found</h1>
                  <p className="mg-bottom-32px">
                    What you were looking for is just not there
                  </p>
                  <div className="inner-container _254px width-100 _100---mbp">
                    <div className="buttons-row center">
                      <a href="/" className="btn-primary full-width w-button">
                        Go somewhere nice
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound404;
