import React from 'react';

const ResumeCard = ({ experience }) => {
  return (
    <div className="card resume-card-v1" style={{ opacity: 1 }}>
      <div className="top-content-resume-card">
        <div className="flex-horizontal space-between reverse-wrap">
          <div className="resume-card-period">{experience.dates}</div>
          <div className="image-wrapper right-shadow-circle small resume-logo">
            <img
              src={experience.companyLogo}
              loading="eager"
              alt="springboard logo"
              className="image"
            />
          </div>
        </div>
      </div>
      <div className="resume-card-divider"></div>
      <div className="bottom-content-resume-card">
        <h3 className="display-4">{experience.title}</h3>
        <p className="mg-bottom-0">
          {experience.companyName} <strong>|</strong> {experience.description}
        </p>
      </div>
    </div>
  );
};

export default ResumeCard;
