import React from 'react';
import avatar from '../images/meitu_avatar.png';
import { PROJECTS } from '../data/projects';
import { EXPERIENCES } from '../data/resumeXp';
// import tshinImg from '../images/tshin.jpg';
import ProjectCard from '../components/cards/ProjectCard';
import ResumeCardS from '../components/cards/ResumeCardS';

const Home = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

  if (!isAuthenticated) {
    window.location.href = '/password';
    return null;
  }

  return (
    <div>
      <div className="section hero v1 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="w-layout-grid grid-2-columns hero-v1">
                <div
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1
                  // }}
                  className="inner-container _600px"
                >
                  <div className="text-center---tablet">
                    <div className="inner-container _550px---tablet center">
                      <h1 className="display-1">
                        I’m{' '}
                        <span className="heading-span-secondary-4">
                          Christien Ng
                        </span>
                        , a Software Dev from{' '}
                        <span className="heading-span-secondary-2">
                          California
                        </span>
                      </h1>
                    </div>
                    <p className="mg-bottom-48px">
                      <b>christien ng [kris-teen -ing] n.</b> || 1. a curious
                      being and creative problem-solver | 2. person with a
                      passion for music, fashion, and health | 3. has a
                      gravitation towards the unknown
                    </p>
                  </div>
                  <div className="buttons-row center-tablet">
                    <a
                      href="/contact"
                      className="btn-primary button-row w-button"
                    >
                      <span className="line-rounded-icon link-icon-left text-medium">
                        
                      </span>
                      Get in touch
                    </a>
                    <a href="/portfolio" className="btn-secondary w-button">
                      <span className="line-rounded-icon link-icon-left">
                        
                      </span>
                      View porfolio
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    // style={{
                    //   transform:
                    //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    //   opacity: 1
                    // }}
                    className="image-wrapper hero-image"
                  >
                    <img
                      src={avatar}
                      loading="eager"
                      alt=" christien ng hero"
                      className="image"
                      style={{ transform: 'scaleX(-1)' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PROJECTS */}
      <div className="section wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div
                // style={{
                //   transform:
                //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1
                // }}
                className="inner-container _500px center"
              >
                <div className="inner-container _300px---mbp center">
                  <div className="mg-bottom-48px">
                    <h2 className="display-2 text-center">
                      <span className="z-index-1">Take a look at my</span>
                      <br />
                      <span className="heading-span-secondary-1">
                        portfolio
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="mg-bottom-72px">
                <div
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1
                  // }}
                  className="w-dyn-list"
                >
                  <div
                    role="list"
                    className="grid-1-column gap-32px w-dyn-items"
                  >
                    {[PROJECTS[0], PROJECTS[2]].map(p => {
                      return <ProjectCard key={p.title} props={p} />;
                    })}
                  </div>
                </div>
              </div>
              <div
                // style={{
                //   transform:
                //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1
                // }}
                className="buttons-row center"
              >
                <a href="/portfolio" className="btn-primary w-button">
                  Browse all portfolio
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EXPERIENCE */}
      <div className="section bg-neutral-800 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="w-layout-grid grid-2-columns blog-left-sidebar gap-column-64px">
                <div
                  id="w-node-eb9abc4f-1a64-dc91-46de-47243848b65b-88b54227"
                  data-w-id="eb9abc4f-1a64-dc91-46de-47243848b65b"
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1
                  // }}
                  className="sticky-top _48px-top sticky-tbl"
                >
                  <div className="inner-container _535px">
                    <div className="text-center---tablet">
                      <div className="inner-container _500px---tablet center">
                        <div className="inner-container _300px---mbl center">
                          <h2 className="display-2 color-neutral-100">
                            <span className="z-index-1">
                              Take a look at my{' '}
                            </span>
                            <span className="heading-span-secondary-3">
                              past experience
                            </span>
                          </h2>
                        </div>
                      </div>
                      <p className="color-neutral-300 mg-bottom-40px">
                        I love to learn and experience new things, and am always
                        up for a challenge regardless of the situation
                      </p>
                      <div className="buttons-row center-tablet">
                        <a href="/about" className="btn-primary white w-button">
                          <span className="line-rounded-icon link-icon-left text-medium">
                            
                          </span>
                          See full resume
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-1-column gap-40px">
                  {[EXPERIENCES[0], EXPERIENCES[1]].map(e => {
                    return <ResumeCardS key={e.companyName} experience={e} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TESTIMONIALS */}
      {/* <div className="section overflow-hidden testimonial-section wf-section">
        <div className="container-default w-container">
          <div
            data-w-id="3264f357-a3b7-75b6-7867-71e5d48777e8"
            // style={{
            //   transform:
            //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            //   opacity: 1
            // }}
            className="inner-container _550px center"
          >
            <div className="inner-container _600px---tablet center">
              <div className="inner-container _500px---mbl center">
                <div className="inner-container _400px---mbp center">
                  <div className="text-center mg-bottom-40px">
                    <div className="inner-container _400px---mbl center">
                      <div className="inner-container _350px---mbp center">
                        <h2 className="display-2">
                          What people have said{' '}
                          <span className="heading-span-secondary-2">
                            about me
                          </span>
                        </h2>
                      </div>
                    </div>
                    <p className="mg-bottom-48px">LinkedIn Recommendations</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-delay="4000"
            data-animation="cross"
            className="slider-wrapper w-slider"
            data-autoplay="false"
            data-easing="ease"
            style={{ opacity: 1 }}
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-w-id="3264f357-a3b7-75b6-7867-71e5d48777ee"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="true"
            role="region"
            aria-label="carousel"
          >
            <div
              className="slider-mask overflow-visible w-slider-mask"
              id="w-slider-mask-0"
            >
              <div
                className="w-slide"
                aria-label="1 of 3"
                role="group"
                // style={{ transform: 'translateX(0px)', opacity: 1 }}
              >
                <div className="inner-container testimonial-slider">
                  <div className="card testimonial-card">
                    <div className="testimonial-card-content-wrapper">
                      <div className="inner-container _500px grow">
                        <div>
                          <div className="mg-bottom-24px mg-top--80px keep">
                            <img
                              src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633b1c81e34cfb82b85454eb_quote-sign-paperflow-webflow-template.svg"
                              loading="eager"
                              alt=""
                            />
                          </div>
                          <p className="mg-bottom-56px">
                            Christien is an exceptional manager and mentor who's
                            helped me in achieving my personal goals as well as
                            the team's overall goals. She has instilled a lot of
                            trust in me and has given me the flexibility to work
                            autonomous, come up with new ideas, and set my own
                            goals. She's very cognizant of my needs and is very
                            approachable, even when it comes to having difficult
                            conversations. Overall, I'm grateful to be able to
                            have Christien as my manager.
                          </p>
                          <div className="flex-horizontal space-between wrap-24px">
                            <div className="full-width-mobile">
                              <div className="mg-bottom-16px">
                                <div className="text-200 bold color-neutral-800">
                                  Terence Shin
                                </div>
                              </div>
                              <div className="text-200 medium">
                                Data Science Blogger
                              </div>
                            </div>
                            <img
                              src="https://miro.medium.com/max/8978/1*s986xIGqhfsN8U--09_AdA.png"
                              loading="eager"
                              alt="koho logo"
                              className="logo-image"
                              style={{ height: '50px' }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-image-wrapper">
                        <img
                          src={tshinImg}
                          loading="eager"
                          alt="lily woods testimonial"
                          className="image drop-shadow"
                          style={{ borderRadius: '50%' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
