import wanderLogo from '../images/wander-logo.png';
import sbLogoDark from '../images/springboard-logo-dark.png';
import datatronLogo from '../images/datatron-logo.png';

export const EXPERIENCES = [
  {
    dates: 'Dec 2023 - Present',
    companyLogo:
      'https://www.mediaocean.com/sites/default/files/styles/medium/public/2021-07/MO%20logo.jpg?itok=JlQjbRm0',
    companyName: 'Mediaocean',
    link: 'https://www.mediaocean.com/',
    title: 'Software Development Engineer',
    description: 'The mission-critical platform for omnichannel advertising',
    duties: [
      'Flashtalking, Scope 2.0, Admin, Snapchat, UAM',
    ],
    current: true
  },
  {
    dates: 'Mar 2023 - Present',
    companyLogo: wanderLogo,
    companyName: 'Wander Travel',
    link: 'https://www.linkedin.com/company/wander-travel/',
    title: 'Software Developer - Freelance',
    description: 'Group travel planning app',
    duties: [
      'Collaborating with cross-functional teams including developers, designers, and product owners to ensure efficient delivery of the app while contributing to Kanban planning and retrospectives',
      'Spearheading mobile adaptivity and responsiveness enhancements, ensuring optimal user experience across various devices',
      'Designing and implementing new app features, as well as troubleshooting and debugging existing code to improve user experience',
      'password: wanderingwheretogo'
    ],
    current: true
  },
  {
    dates: 'Jun 2023 - Aug 2023',
    companyLogo:
      'https://www.mediaocean.com/sites/default/files/styles/medium/public/2021-07/MO%20logo.jpg?itok=JlQjbRm0',
    companyName: 'Mediaocean',
    link: 'https://www.mediaocean.com/',
    title: 'Software Engineering Intern',
    description: 'The mission-critical platform for omnichannel advertising',
    duties: [
      // 'Building out new features, fixing bugs, having fun.',
      'Developed and implemented a Token Management Feature in the Flashtalking/Scope platform, aiming to address time-sensitive issues related to active user tokens, enhancing user workflows and ensuring smooth access to multiple accounts and publisher APIs',
      'Collaborated with UX designers, product managers, and developers to gather requirements, identify user pain points, and propose iterative improvements, resulting in enhanced product stability and reduced disruptions',
      'Resolved frontend bugs in the UAM system, improving interface usability, user experience, and data management efficiency for seamless application usage',
    ],
    current: false
  },
  {
    dates: 'Mar 2023 - Dec 2023',
    companyLogo: 'https://avatars.githubusercontent.com/u/11635254?s=200&v=4',
    companyName: 'HackforLA',
    link: 'https://www.hackforla.org/',
    title: 'Software Developer - Volunteer',
    description:
      'Civic organization working with nonprofit, government, and private partners',
    duties: [
      'Contributing as a software developer to Hack for LA, an open-source community dedicated to improving access to technology for underserved communities',
      'Maintaining and developing code in JavaScript, HTML, CSS, Flask, React, and Git, enhancing various projects and initiatives',
      'Collaborating with other volunteers to fix bugs, implement new features, and ensure adherence to coding standards and best practices'
    ],
    current: false
  },
  {
    dates: 'Sept 2021 - Mar 2023',
    companyLogo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9H9nmGhFmeFtYfwA37sSH1ZazkPNSB8sol91lnuI9-eDmTPVQHIMMNPwPJfeT8DNBFWc&usqp=CAU',
    companyName: 'Keller Williams Realty',
    link: 'https://ranchocucamonga.yourkwoffice.com/',
    title: 'Real Estate Agent',
    description: 'Salesperson',
    duties: ['DRE# 02096130'],
    current: false
  },
  {
    dates: 'Dec 2021 - Jan 2023',
    companyLogo: sbLogoDark,
    companyName: 'Springboard',
    link: 'https://springboard.com/',
    title: 'Software Engineering Fellow',
    description: 'Software Engineering Bootcamp',
    duties: [
      'Demonstrated proficiency in web development through completion of over 800 hours of hands-on coursework under the guidance of industry experts',
      'Exhibited strong project management skills by successfully completing 4 complex web development projects, covering a range of front-end, back-end, and full-stack development techniques',
      'Foundational skills in Web Development, JavaScript, DOM Manipulation, Event Driven Programming, Git/Terminal/GitHub, Modern JavaScript and Testing, AJAX, jQuery, ReactJS, Redux, SQL, PostgreSQL, Flask, Node, Express, Data Structures and Algorithms'
    ],
    current: false
  },
  {
    dates: 'Aug 2019 - Jun 2020',
    companyLogo: datatronLogo,
    companyName: 'Datatron',
    link: 'https://datatron.io/',
    title: 'Chief of Staff',
    description: 'Early Stage Startup',
    duties: [
      'Supported CEO, CPO, Technical Project Manager, and a team of 16 in maintaining efficient internal workflow and communication among engineering, administrative, and recruitment teams',
      'Collaborated with cross-functional teams including developers and designers to optimize website for optimal responsiveness and relevance to improve user experience'
    ],
    current: false
  }
  // {
  //   dates: 'Dec 2018 - Aug 2019',
  //   companyLogo: 'https://cdn-icons-png.flaticon.com/512/4660/4660779.png',
  //   companyName: 'E-Commerce Clothing Store',
  //   link: '',
  //   title: 'Project Manager',
  //   description: 'Freelance project with friends',
  //   duties: [
  //     'Developed and maintained product information listings for an online store, including product descriptions, graphics, and pricing, ensuring that all information was updated and accurate at all times',
  //     'Utilized affiliate marketing, referrals, and social media influencers to spur growth and attract new customers, resulting in a 40% increase in referral traffic and a 30% increase in new visitor traffic'
  //   ],
  // current: false
  // }
];
