import React from 'react';

const ProjectCard = ({ props }) => {
  return (
    <div role="listitem" className="w-dyn-item">
      <a
        href={props.githubRepo ? props.githubRepo : props.liveSite}
        target="_blank"
        rel="noreferrer"
        // href={'/portfolio-projects/' + props.title}
        className="card porfolio-project-wrapper link-card w-inline-block"
      >
        <div className="w-layout-grid grid-2-columns blog-card-featured card-shadow">
          <div className="blog-card-featured-inner-content">
            <div className="mg-bottom-32px">
              <div className="flex-horizontal start gap-24px">
                <img
                  alt="company logo"
                  src={props.companyLogo}
                  className="logo-image small"
                  style={{ maxWidth: '120px', maxHeight: '80px' }}
                />
                <div className="badge-primary small">
                  <div>{props.date}</div>
                </div>
              </div>
            </div>
            <h2 className="display-3">{props.title}</h2>
            <p className="mg-bottom-56px mg-bottom-32px-tablet">
              {props.description}
            </p>
            <h6>Technologies:</h6>
            <p className="technologies">{props.technologies}</p>
            <div className="link-wrapper color-neutral-800 text-bold">
              <div className="link-text color-neutral-800">
                {props.githubRepo ? 'Visit Repo' : 'Visit Site'}
              </div>
              <div className="line-rounded-icon link-icon-right color-neutral-800">
                
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: props.bgColor }}
            className="blog-card-image-wrapper featured-v2"
          >
            <img
              src={props.image}
              loading="eager"
              alt="Twitch user research and analysis"
              className="image fit-contain"
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProjectCard;
