import React from 'react';
import { EXPERIENCES } from '../data/resumeXp';
import christien from '../images/christien.jpg';
import avatar from '../images/meitu_avatar.png';
import aboutAvi from '../images/about-avi.png';
import ResumeCardM from '../components/cards/ResumeCardM';

const About = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

  if (!isAuthenticated) {
    window.location.href = '/password';
    return null;
  }

  return (
    <div>
      <div className="section hero v2 overflow-hidden wf-section">
        <div className="container-default w-container">
          <div className="grid-3-columns _3-col-hero">
            <div>
              <img
                src={christien}
                className="drop-shadow"
                loading="eager"
                style={{
                  borderRadius: '50%',
                  width: '400px'
                  //   opacity: 1,
                  //   transform:
                  //     'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                }}
                alt="about hero"
              />
            </div>
            <div className="inner-container _600px">
              <div className="inner-container _550px---mbl">
                <div className="mg-top-64px mg-top-0px---mbl">
                  <div className="mg-borrom-35px mg-bottom-0px---mbl">
                    <div
                      // style={{
                      //   transform:
                      //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      //   opacity: 1
                      // }}
                      className="text-center"
                    >
                      <div className="inner-container _400px---mbp center">
                        <div className="inner-container _400px---mbl center">
                          <h1 className="display-1">
                            Hello, I’m <br />
                            <span className="heading-span-secondary-2">
                              Christien Ng
                            </span>
                          </h1>
                        </div>
                        <p className="mg-bottom-40px">
                          software developer
                        </p>
                      </div>
                      <div className="buttons-row center">
                        <a
                          href="/contact"
                          className="btn-primary button-row w-button"
                        >
                          <span className="line-rounded-icon link-icon-left text-medium">
                            
                          </span>
                          Get in touch
                        </a>
                        <a href="#My-Story" className="btn-secondary w-button">
                          <span className="line-rounded-icon link-icon-left text-medium">
                            
                          </span>
                          My story
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-wrapper max-width-270px right">
              <img
                src={avatar}
                loading="eager"
                className="image-wrapper hero-image"
                style={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '50%',
                  objectFit: 'cover'
                  //   opacity: 1,
                  //   transform:
                  //     'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                }}
                alt="about hero"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="My-Story" className="section pd-top-0 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="w-layout-grid grid-2-columns text-right-default story-grid">
                <div className="inner-container _583px _100---tablet">
                  <div className="mg-bottom-54px">
                    <div className="text-center---tablet">
                      <h2
                        // style={{
                        //   transform:
                        //     'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)rotateX(0deg) rotateY(0deg) rotateZ(0deg)skew(0deg, 0deg)',
                        //   opacity: 1
                        // }}
                        className="display-2 mg-bottom-0"
                      >
                        My{' '}
                        <span className="heading-span-secondary-3">story</span>{' '}
                        as a developer
                      </h2>
                    </div>
                  </div>
                  <div className="inner-container _553px _100---tablet">
                    <div className="image-wrapper">
                      <img
                        src={aboutAvi}
                        alt="my story"
                        style={{ opacity: 1 }}
                        sizes="(max-width: 479px) 92vw, (max-width: 767px) 90vw, (max-width: 991px) 600px, (max-width: 1439px) 45vw, 553px"
                        loading="eager"
                      />
                    </div>
                  </div>
                </div>
                <div
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1
                  // }}
                  className="inner-container _580px _100---tablet"
                >
                  <p className="mg-bottom-24px keep w-clearfix">
                    <span className="drop-cap-span">M</span>y first interaction
                    with coding was back in the late 2000's when MySpace and
                    Tumblr were at their prime. I thought I was a wizard
                    disecting HTML/CSS to customize my profile pages. I've
                    always loved solving puzzles and have a natural engineering
                    mindset of wanting to build things and also breaking them
                    down to see how they work—How It's Made was my favorite show
                    growing up. But I guess I just never imagined it as a career
                    because I used to think engineers, in general, had to be
                    exceptionally smart (and male), and I didn't believe it was
                    possible for me as there weren't many female role models I
                    could look up to back then.
                  </p>
                  <p className="mg-bottom-40px">
                    I've always had a passion for understanding how technology
                    can improve our daily lives, and working at a startup gave
                    me the opportunity to explore that passion. However, it
                    wasn't until the pandemic hit that I decided to explore my
                    love for coding. Flash forward to today, I've completed a
                    coding bootcamp learning fullstack development, and am ready
                    to take on the tech world with my background in business ops
                    and newfound technical skills 💪🏼
                  </p>
                  <div className="buttons-row">
                    <a href="/contact" className="btn-primary w-button">
                      <span className="line-rounded-icon link-icon-left text-medium">
                        
                      </span>
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-neutral-800 wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="w-layout-grid grid-2-columns story-grid-v2">
                <div
                  // style={{
                  //   transform:
                  //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  //   opacity: 1
                  // }}
                  className="inner-container _584px _100---tablet"
                >
                  <div className="inner-container _300px---mbp">
                    <h2 className="display-2 color-neutral-100">
                      Some{' '}
                      <span className="heading-span-secondary-4">
                        background
                      </span>{' '}
                      on me
                    </h2>
                  </div>
                  <p className="color-neutral-300 mg-bottom-24px">
                    I grew up in Kentucky but moved to California for college
                    and to be closer to the family I had here. I graduated from
                    UC Riverside with a degree in Business Administration with a
                    focus on Information Systems and a lot of marketing
                    electives. As a curious being and creative problem-solver, I
                    gather inspiration from all aspects of life—especially
                    through music, fashion, and culture. My gravitation towards
                    the unknown drives me to ask questions and constantly look
                    for ways to to expand my mind. My dad is our family's
                    handyman, so growing up, I learned to look at things from
                    different perspectives and problem-solve creatively (and
                    that Google is your best friend). I enjoy working on things
                    that improves people's lives, no matter how small the impact
                    may be.
                  </p>
                  <div className="mg-bottom-40px">
                    <div className="mg-bottom-12px">
                      <div className="flex-horizontal align-top---justify-left gap-16px">
                        <div className="bullet bg-secondary-3 white mg-top-5px"></div>
                        <p className="color-neutral-100 mg-bottom-0">
                          I taught myself how to ride a bike
                        </p>
                      </div>
                    </div>
                    <div className="mg-bottom-12px">
                      <div className="flex-horizontal align-top---justify-left gap-16px">
                        <div className="bullet bg-secondary-2 white mg-top-5px"></div>
                        <p className="color-neutral-100 mg-bottom-0">
                          I can say the alphabet backwards in under 5 seconds
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="flex-horizontal align-top---justify-left gap-16px">
                        <div className="bullet bg-secondary-1 white mg-top-5px"></div>
                        <p className="color-neutral-100 mg-bottom-0">
                          I have a dog named Peanut who loves going down slides
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-row">
                    <a
                      href="/contact"
                      className="btn-secondary variant w-button"
                    >
                      <span className="line-rounded-icon link-icon-left text-medium">
                        
                      </span>
                      Get in touch
                    </a>
                  </div>
                </div>
                <div className="inner-container story-grid-v2---image">
                  <img
                    src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633b55bcb4baec57b75b66fd_desigining-experience-paperfolio-webflow-template.png"
                    alt="experience desiginig"
                    className="image-wrapper hero-image"
                    // style={{
                    //   transform:
                    //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    //   opacity: 1
                    // }}
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 90vw, (max-width: 991px) 600px, (max-width: 1439px) 41vw, 560px"
                    loading="eager"
                    srcSet="
                      https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633b55bcb4baec57b75b66fd_desigining-experience-paperfolio-webflow-template-p-500.png  500w,
                      https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633b55bcb4baec57b75b66fd_desigining-experience-paperfolio-webflow-template.png       1120w
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wf-section">
        <div className="container-default w-container" id="my-resume">
          <div className="inner-container _500px---mbl center">
            <h2
              // style={{
              //   transform:
              //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1)rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              //   opacity: 1
              // }}
              className="display-2 text-center mg-bottom-56px"
            >
              Take a look at my{' '}
              <span className="heading-span-secondary-1">resume</span>
            </h2>
            <div className="inner-container _935px center">
              <div className="grid-1-column gap-32px mg-bottom-64px">
                {EXPERIENCES.map(e => {
                  return <ResumeCardM key={e.companyName} experience={e} />;
                })}
              </div>
            </div>
            <div
              // style={{
              //   transform:
              //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              //   opacity: 1
              // }}
              className="buttons-row center"
            >
              <a
                href="https://docs.google.com/document/d/1bEq4WCMKdNr1rxbbaBanKKKopOEt0AoF/edit"
                target="_blank"
                rel="noreferrer"
                className="btn-primary w-button"
              >
                View PDF Resume
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section pd-200px wf-section">
        <div className="container-default w-container">
          <div className="inner-container _600px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="mg-bottom-54px">
                <div className="text-center---tablet">
                  <div className="w-layout-grid grid-2-columns title-and-paragraph">
                    <div className="inner-container _525px">
                      <div className="inner-container _400px---mbl center">
                        <div className="inner-container _350px---mbp center">
                          <h2 className="display-2 mg-bottom-0">
                            The core values that drive my work
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="inner-container _525px">
                      <p className="mg-bottom-0">
                        I try to live by these values and I am excited to join a
                        team that shares similar beliefs and mindsets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="796ca038-4e1a-83d9-63b4-60926ca1217c"
                // style={{
                //   transform:
                //     'translate3d(0px, 10%, 0px) scale3d(1, 1, 1)rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                //   opacity: 1,
                //   transformStyle: 'preserve-3d'
                // }}
                className="w-layout-grid grid-2-columns values-grid"
              >
                <div className="card image-left---text-right">
                  <div className="image-wrapper card-value-image-left-wrapper">
                    <img
                      src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633b6863165495ea4480a18a_hard-work-image-paperfolio-webflow-template.png"
                      loading="eager"
                      alt="hard work"
                      className="image fit-cover"
                    />
                  </div>
                  <div className="card-value-center-left">
                    <h3>Organization</h3>
                    <p className="mg-bottom-0">
                      Prioritizing tasks, utilizing time efficiently.
                    </p>
                  </div>
                </div>
                <div className="card image-left---text-right">
                  <div className="image-wrapper card-value-image-left-wrapper">
                    <img
                      src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633c47da68ca183fc8091e34_transparency-image-paperfolio-webflow-template.png"
                      loading="eager"
                      alt="transparency"
                      className="image fit-cover"
                    />
                  </div>
                  <div className="card-value-center-left">
                    <h3>Transparency</h3>
                    <p className="mg-bottom-0">
                      Effective communication, honesty, and trust-building.
                    </p>
                  </div>
                </div>
                <div className="card image-left---text-right">
                  <div className="image-wrapper card-value-image-left-wrapper">
                    <img
                      src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633c47da6cc8718f2fb6d9ac_innovation-image-paperfolio-webflow-template.png"
                      loading="eager"
                      alt="innovation"
                      className="image fit-cover"
                    />
                  </div>
                  <div className="card-value-center-left">
                    <h3>Innovation</h3>
                    <p className="mg-bottom-0">
                      Finding creative solutions for staying ahead.
                    </p>
                  </div>
                </div>
                <div className="card image-left---text-right">
                  <div className="image-wrapper card-value-image-left-wrapper">
                    <img
                      src="https://assets.website-files.com/63360c0c2b86f80ba8b5421a/633c47da606f910b55d8f1f4_growth-image-paperfolio-webflow-template.png"
                      loading="eager"
                      alt="growth"
                      className="image fit-cover"
                    />
                  </div>
                  <div className="card-value-center-left">
                    <h3>Growth</h3>
                    <p className="mg-bottom-0">
                      Continuous learning and improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
